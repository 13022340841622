import { TEXT_STRING } from '../../constants';
import Chip from 'components/Chip';
import Icon from 'components/Icon';
import TextField from 'components/TextField';
import { ReactElement } from 'react';
import { TActivityArea, TTextFieldChangeEvent } from 'types';

const STR = TEXT_STRING.ORDER_DETAIL;

type Props = {
  onChangeArea: (value: string) => void;
  name: string;
  areaValue: string;
  data: TActivityArea[];
  onSelectArea: (id: number, area: string) => void;
  onSelectOtherArea: () => void;
  isSelectOtherArea: boolean;
  selectedAreaId: number;
};

type CustomChipProps = {
  isActive: boolean;
  onClick: () => void;
  content: ReactElement;
};

const CustomChip = ({
  isActive,
  onClick,
  content,
}: CustomChipProps): JSX.Element => {
  return (
    <Chip
      ringColor={isActive ? 'ring-transparent' : 'ring-neutral-300'}
      textColor={isActive ? 'text-white' : 'text-neutral-400'}
      bgColor={isActive ? 'bg-primary' : ''}
      onClick={onClick}
      className="cursor-pointer"
    >
      {content}
    </Chip>
  );
};

const ListArea = ({
  onChangeArea,
  name,
  areaValue,
  data,
  selectedAreaId,
  onSelectArea,
  onSelectOtherArea,
  isSelectOtherArea,
}: Props): JSX.Element => {
  return (
    <>
      <div className="flex flex-wrap gap-3">
        {data.map((area) => {
          return (
            <CustomChip
              key={area.id}
              isActive={area.id === selectedAreaId}
              onClick={() => onSelectArea(area.id, area.name)}
              content={<>{area.name}</>}
            />
          );
        })}
        <CustomChip
          isActive={isSelectOtherArea}
          onClick={onSelectOtherArea}
          content={
            <>
              <div className="scale-[0.8]">
                <Icon
                  icon={'plus'}
                  color={isSelectOtherArea ? 'white' : 'var(--color-neutral-400)'}
                />
              </div>
              {STR.OTHERS}
            </>
          }
        />
      </div>
      {isSelectOtherArea && (
        <TextField
          name={name}
          value={areaValue}
          placeholder={STR.ENTER_LOCATION}
          onChange={(event: TTextFieldChangeEvent) => {
            onChangeArea(event.target.value);
          }}
          className="py-0.5"
        />
      )}
    </>
  );
};

export default ListArea;
