import { Divider, Header, Loading, Text, VideoGame } from 'components';
import { TEXT_STRING, queryKeys } from '../../constants';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getGameList } from 'api';
import { EGameType, TVideoGame } from 'types';
import { useSize } from 'hooks';

const GAME_STR = TEXT_STRING.GAME_PAGE;
const BANNER_URL = process.env.REACT_APP_BANNER_URL;
const BANNER_POSTER_URL = process.env.REACT_APP_BANNER_POSTER_URL;

function GamePage() {
  const { BannerView } = useSize({
    url: BANNER_URL,
    poster: BANNER_POSTER_URL,
  });

  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.VIDEO_GAME],
    queryFn: getGameList,
  });

  const [normalGame, kingGame, specialGame] = useMemo(() => {
    const filterAndMapGames = (
      filterCondition: (game: TVideoGame) => boolean
    ) =>
      data
        ?.filter((game) => filterCondition(game) && !!game.videoUrl)
        ?.map((game) => <VideoGame key={game.id} data={game} />);

    const normalGame = filterAndMapGames(
      (game) =>
        game.type !== EGameType.GROUP_KING &&
        game.type !== EGameType.COMMON_SPECIAL
    );
    const kingGame = filterAndMapGames(
      (game) => game.type === EGameType.GROUP_KING
    );
    const specialGame = filterAndMapGames(
      (game) => game.type === EGameType.COMMON_SPECIAL
    );

    return [normalGame, kingGame, specialGame];
  }, [data]);

  if (isLoading) {
    return <Loading fullScreen center />;
  }

  return (
    <div className="h-full overflow-y-auto">
      <Header title={<Text bold>{TEXT_STRING.COMMON.GAME}</Text>} />
      <div className="h-full flex flex-col p-4 gap-5">
        {BannerView && (
          <>
            <Divider title={TEXT_STRING.GAME_PAGE.WHAT_IS_TECO} />
            {BannerView}
          </>
        )}

        <Divider title={GAME_STR.INDIVIDUAL_LEVER} />
        <div className="grid grid-cols-2 gap-3">{specialGame}</div>

        <Divider title={GAME_STR.NORMAL_GAME} />
        <div className="grid grid-cols-2 gap-3">
          {normalGame}
          <div className="bg-primary-400 flex flex-col gap-1 p-4">
            <Text bold center>
              {GAME_STR.ABOUT_KING_APP}
            </Text>
            <Text bold>
              {GAME_STR.KING_APP_STEP_1} <br />
              {GAME_STR.KING_APP_STEP_2} <br />
              <u>{GAME_STR.KING_APP_STEP_3}</u>
            </Text>
          </div>
        </div>

        <Divider title={GAME_STR.GROUP_LEVERAGE_GAME} />
        <div className="grid grid-cols-2 gap-3">{kingGame}</div>

        <Text className="pb-4">{GAME_STR.NOTE_LEVERAGE_GAME}</Text>
      </div>
    </div>
  );
}

export default GamePage;
