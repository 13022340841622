import DraggableModal from 'components/DraggableModal';
import { queryKeys, TEXT_STRING } from '../../../constants';
import {
  EGiftPaymentStep,
  EPaymentMethod,
  TBodyGiftRequest,
  TBodyPaymentGift,
  TResPaymentGift,
} from 'types';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getGiftPendingPayment,
  getGiftPredict,
  paymentGift,
  paymentGiftRequest,
} from 'api';
import CardPayment from 'components/CardPayment';
import { useCallback, useContext } from 'react';
import Button from 'components/Button';
import { ChatContext } from 'components/Chat/context';
import Loading from 'components/Loading';
import ProcessingPriceList from 'components/ProcessingPriceList';

type Props = {
  step?: EGiftPaymentStep;
  onClose: () => void;
  giftId: number;
  femaleId?: number;
  onNextStep: () => void;
  onPreviousStep?: () => void;
  onSendMessage: (data: TResPaymentGift) => void;
};

const GiftPaymentStep = ({
  step,
  onClose,
  onNextStep,
  onPreviousStep,
  giftId,
  femaleId,
  onSendMessage,
}: Props) => {
  // Hooks
  const { historyPaymentId, setHistoryPaymentId } = useContext(ChatContext);

  // Query
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.GIFT_PREDICT, giftId],
    queryFn: () => getGiftPredict({ giftId }),
    enabled:
      !!giftId && !historyPaymentId && step === EGiftPaymentStep.CHOOSE_CARD,
  });

  const {
    data: pendingGift,
    isLoading: loadingPendingGift,
    isRefetching,
  } = useQuery({
    queryKey: [queryKeys.GIFT_PENDING_PAYMENT, historyPaymentId],
    queryFn: () => getGiftPendingPayment(historyPaymentId || 0),
    enabled: !!historyPaymentId,
  });

  // Mutations
  const { mutateAsync: payGift, isPending: payingGift } = useMutation({
    mutationFn: (body: TBodyPaymentGift) => {
      return paymentGift(body);
    },
    onSuccess(data) {
      onSendMessage(data);
    },
  });

  const { mutateAsync: payGiftPending, isPending: paying } = useMutation({
    mutationFn: (body: TBodyGiftRequest) => {
      return paymentGiftRequest(body);
    },
    onSuccess(data) {
      onSendMessage(data);
    },
  });

  // Memo, callbacks
  const titleModal = {
    [EGiftPaymentStep.GIFTS_PREDICT]: TEXT_STRING.GIFT.TOTAL_STAMPS,
    [EGiftPaymentStep.CHOOSE_CARD]: TEXT_STRING.GIFT.CREDIT_REGISTRATION,
  };

  const handleClose = useCallback(() => {
    if (!paying && !payingGift) {
      setHistoryPaymentId(undefined);
      onClose();
    }
  }, [onClose, paying, payingGift, setHistoryPaymentId]);

  const handlePayment = useCallback(
    async (token: string) => {
      if (!token) return;
      if (historyPaymentId) {
        await payGiftPending({
          token,
          historyId: historyPaymentId,
          pendingIds: [],
        });
      } else {
        if (femaleId && giftId) {
          await payGift({
            token,
            femaleId,
            giftId,
            type: EPaymentMethod.CARD,
          });
        }
      }
    },
    [historyPaymentId, payGiftPending, femaleId, giftId, payGift]
  );

  return (
    <>
      <DraggableModal
        onClose={handleClose}
        open={!!step}
        title={titleModal[step || EGiftPaymentStep.GIFTS_PREDICT]}
        className="pb-4"
      >
        {step === EGiftPaymentStep.GIFTS_PREDICT && (
          <ProcessingPriceList
            processing={pendingGift?.pending}
            totalAmount={pendingGift?.totalAmount}
            onNextStep={onNextStep}
            disabled={pendingGift?.isSuccess}
            isRefetching={isRefetching}
            isLoading={loadingPendingGift}
          />
        )}
        {step === EGiftPaymentStep.CHOOSE_CARD && (
          <>
            {isLoading ? (
              <Loading />
            ) : (
              <CardPayment
                price={pendingGift?.remainingAmount || data?.totalAmount}
                paying={payingGift || paying}
                onPay={handlePayment}
                title=""
                subTitle={
                  TEXT_STRING.CALL_WOMAN.PAYMENT_WARNING +
                  '\n' +
                  TEXT_STRING.GIFT.SUPPORT_PAYMENT
                }
                onSettled={handleClose}
              />
            )}
          </>
        )}
        {historyPaymentId && (
          <Button
            onClick={() => {
              onPreviousStep && onPreviousStep();
            }}
            bgColor="bg-transparent"
            textColor="text-primary"
            disabled={paying || payingGift}
            padding=""
          >
            {TEXT_STRING.COMMON.RETURN}
          </Button>
        )}
      </DraggableModal>
    </>
  );
};

export default GiftPaymentStep;
