const TEXT_APP_INFO = {
  ABOUT_APP_CONTENT: [],
  COMMUNICATION_GUIDE_CONTENT: [],
  PRIVACY_POLICY_CONTENT: [],
  LEGAL_COMPLIANCE_POLICY_CONTENT: [],
  TERMS_OF_SERVICE_CONTENT: [
    {
      text: '第１条（利用規約）',
      children: [
        {
          text: 'teco（以下、「当社」といいます。）は、本利用規約（以下、「本規約」といいます。）を定め、本規約に従い、サービス（以下、「本サービス」といいます。）を会員に提供します。',
        },
        {
          text: '当社は、本規約の他に、個別にサービスごとに規約（以下、「個別規約」といいます。）を定めることがあります。本規約と個別規約の定めが異なる場合、個別規約の定めが優先して適用されるものとします。',
        },
        {
          text: '個別規約、本サービスのヘルプ、ガイドページその他本サービスを説明するページも、別段の定めがある場合を除き、本規約の一部を構成するものとし、これらを合わせて本規約とします。なお、本規約は民法５４８条の２が定める定型約款に該当します。',
        },
        {
          text: '会員は、本規約に同意の上、本サービスを利用するものとします。',
        },
        { text: '本サービスは、１８歳以上でなければ利用できません。' },
      ],
    },
    {
      text: '第2条（規約の変更）',
      content:
        '当社は、必要と判断した場合、本規約を民法５４８条の４の規定に基づき変更することがあります。変更を行う旨及び変更後の本規約の内容並びにその効力発生時期は、効力発生時期が到来するまでにウェブサイトへの掲示、電子メール、アプリケーションのプッシュ通知又はその他相当の方法により周知します。',
    },
    {
      text: '第3条（会員に対する通知方法・会員からの連絡方法）',
      children: [
        {
          text: '当社からの会員に対する通知は、電子メール、ウェブサイトでの掲示、アプリケーションのプッシュ通知その他当社が適当と判断する方法により行います。',
        },
        {
          text: '通知が電子メールで行われる場合、当社が会員宛に電子メールを発信した時点で当該通知が会員に到達したものとみなします。',
        },
        {
          text: '通知をウェブサイトに掲示する場合、通知がウェブサイトに掲示された時点で当該通知が会員に到達したものとみなします。',
        },
        {
          text: '通知がアプリケーションのプッシュ通知で行われる場合、当社がプッシュ通知を発信した時点で当該通知が会員に到達したものとみなします。',
        },
        {
          text: '会員からの当社に対する連絡は、当社が指定する方法に従い、行うものとします。',
        },
      ],
    },
    {
      text: '第4条（会員登録）',
      children: [
        {
          text: '本サービスの利用を希望する者は、当社が指定する方法により会員登録を行うものとします。',
        },
        {
          text: '本サービスを利用するには、１８歳以上である必要があります。本サービスの利用を希望する者は、会員登録に際して、本規約をよく確認した上で、これに同意しなければ会員登録することができません。',
        },
        {
          text: '当社は、本サービスの利用を希望する者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあります。',
          children: [
            '当社に提供した登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合',
            '反社会的勢力等（暴力団、暴力団構成員、準構成員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治活動標ぼうゴロ、特殊知能暴力等又はこれらに準ずる者）に該当すると当社が判断した場合',
            '過去に本規約等に違反した者又はその関係者であると当社が判断した場合',
            'その他、登録が適当でないと当社が判断した場合',
          ],
        },
      ],
    },
    {
      text: '第5条（譲渡等の禁止）',
      content:
        '当社は、会員が会員たる地位、会員たる地位に基づき本サービスを利用する権利並びに会員の権利又は義務を第三者に譲渡、販売、貸与、承継、使用許諾その他処分することを禁止します。',
    },
    {
      text: '第6条（登録情報の変更）',
      children: [
        {
          text: '会員は、メールアドレス、電話番号、クレジットカード情報その他当社に登録した情報に変更が生じた場合、直ちに、登録情報の変更を行う義務を負うものとします。',
        },
        {
          text: '登録情報の変更を怠ったことにより会員に生じた不利益、損害等については、当社は一切の責任を負わないものとします。',
        },
      ],
    },
    {
      text: '第7条（退会）',
      children: [
        {
          text: '会員が本サービスからの退会を希望する場合は、当社所定の方法により、手続を行うものとします。',
        },
        {
          text: '会員は、退会により、会員たる地位を喪失し、本サービスの利用ができなくなるものとします。また、退会終了後は、喪失した会員たる地位を復元することはできません。退会により会員が何らかの不利益を被る結果が生じたとしても、当社は一切の責任を負いません。',
        },
        {
          text: '会員は、当社に対し金銭債務その他債務を負担する場合、退会によりこれを免れるものではありません。',
        },
      ],
    },
    {
      text: '第8条（個人情報の取扱い）',
      children: [
        {
          text: '「個人情報」とは、個人情報の保護に関する法律（以下、「個人情報保護法」といいます。）にいう「個人情報」を指し、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの又は個人識別符号が含まれるものを指します。',
        },
        {
          text: '当社は、以下の目的に必要な範囲で、利用者の個人情報を取得し、取得した情報を利用させていただきます。以下の目的を超えて個人情報を利用する場合には、事前に適切な方法で利用者からの同意を得るものとします。',
          children: [
            '本サービスを提供するため',
            '本サービスの内容を改良・改善し、又は新サービスを開発するため',
            '本サービスの新機能、更新情報、キャンペーン等及び当社又は第三者が提供する他のサービスのご案内のため',
            'メンテナンス、重要なお知らせなど必要に応じたご連絡のため',
            '本サービスに関する利用者からのお問い合わせに回答するため（本人確認を行うことを含む）',
            '本サービスの利用状況を利用者にご報告するため',
            '本サービスに関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし、又はその結果などをご報告するため',
            '本サービスの利用履歴等を調査・分析し、その結果を本サービスの改良・開発や広告の配信に利用するため',
            '利用規約に違反した利用者や、不正・不当な目的で本サービスを利用しようとする利用者の特定をし、ご利用をお断りするため',
          ],
        },
        {
          text: '当社は、本サービスの提供にあたって、会員の位置情報等のデータを取得いたします。また、会員は、当社が会員の位置情報等のデータを取得することについて同意するものとします。',
        },
        {
          text: '個人情報の管理は、厳重に行うこととし、次に掲げる場合を除き、利用者の同意がない限り、第三者に対しデータを開示・提供することはいたしません。また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。',
          children: [
            '人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難である場合',
            '公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難である場合',
            '国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合',
            '業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合',
            '合併その他の事由による事業の承継に伴って個人情報が提供される場合',
            '個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、あらかじめ利用者に通知し、又は利用者が知り得る状態に置いた場合',
            'その他法令で認められる場合',
          ],
        },
        {
          text: '前項の定めにかかわらず、当社は、マーケティング等のために会員の個人データを第三者に提供する場合があります。',
        },
        {
          text: '当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を委託する場合がございます。この場合、当社は、委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な監督を行います。また、当社は、取得個人情報の全部又は一部を、個人情報保護法の定めに基づいて共同利用することがございます。',
        },
        {
          text: '当社は、利用者から個人情報の開示を求められたときは、利用者に対し、遅滞なくこれを開示します。但し、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。',
          children: [
            '利用者又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合',
            '当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合',
            'その他法令に違反することとなる場合',
          ],
        },
        {
          text: '当社の保有する個人情報が誤った情報である場合には、利用者の請求により、当社が定める手続きに従い個人情報の訂正又は削除を行います。また当社は、利用者から前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正又は削除を行い、これを利用者に通知します。',
        },
        {
          text: '当社は、利用者から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、又は不正の手段により取得されたものであるという理由により、その利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨利用者に通知します。但し、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、利用者の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。',
        },
      ],
    },
    {
      text: '第9条（tポイント等）',
      children: [
        {
          text: 'tポイントとは、当社の指定するコンテンツ（デジタルコンテンツを含みます。）を利用するためのポイントをいいます。',
        },
        {
          text: '会員は、当社が指定するクレジットカード事業者等（以下、「決済会社」といいます。）が提供する決済手段により、tポイントを購入することができます。',
        },
        {
          text: '会員は、tポイントを当社の定める方法により利用することで、当社の定める範囲のコンテンツ利用権を取得することができるものとします。tポイントは、当社の指定するサービス内でのみ利用することができます。',
        },
        {
          text: '会員は、当社が特に認めた場合を除き、tポイント及びコンテンツの利用権を他の会員その他第三者に利用させ、又は貸与、譲渡、売買、質入等をすることはできないものとします。',
        },
        {
          text: '会員は、当社が特に求めた場合を除き、tポイントの払戻し、又はtポイントと当社の指定するコンテンツ以外のコンテンツとの交換を求めることができないものとします。',
        },
        {
          text: '会員が退会等により本サービスの利用資格を喪失した場合は、未利用分のtポイントも消滅するものとします。',
        },
      ],
    },
    {
      text: '第10条（料金の支払い等）',
      children: [
        {
          text: '会員は、当社所定の方法により、本サービス上にて、キャストの提供するサービスの申込をすることにより、当社との間で業務委託契約が成立します。なお、当該サービス料金については、会員は、当社所定の方法により当社に支払うものとします。',
        },
        {
          text: '業務委託契約が成立したにもかかわらず、会員が正当な理由なくキャンセルしたと当社が判断した場合、当社は、当該会員に対し、当該業務委託契約の成立に基づき支払った料金を返還しないものとします。',
        },
        {
          text: 'キャストと会員との間の業務委託契約が、会員の責に帰すべき事由により、当初設定した終了時刻よりも早期に終了することとなった場合でも、当初設定した時間に対応した料金が発生するものとします。',
        },
        {
          text: '当該業務委託契約が、当初設定された終了時刻に終了しなかった場合は、当該業務委託契約は自動的に延長されるものとし、当該業務委託契約において当初予定されていた料金に加え、延長分の料金が発生するものとします。なお、延長時間は６０分で１セットとし、以後、６０分が経過するたびに１セット分の延長時間が加算されるものとします。',
        },
        {
          text: 'キャストと会員との間の有料ゲーム等の業務委託契約は、会員が本サービス上でサービスの申込をし、キャストがこれを承諾した場合に成立するものとします。この場合において、キャストと会員との間で締結された有料ゲーム等の業務委託契約の委託料については、会員が当該キャストに対し直接料金を支払うものとします。',
        },
      ],
    },
    {
      text: '第11条（会員の自己責任）',
      children: [
        {
          text: '会員は、本サービスの利用に関連して、会員と第三者との間で紛争が生じた場合、会員の責任と費用において、当該紛争を解決するものとします。',
        },
        {
          text: '会員は、本サービスの利用に関連して、第三者に損害を与えた場合、会員の責任を費用において、当該損害を賠償するものとします。',
        },
      ],
    },
    {
      text: '第12条（禁止行為）',
      children: [
        {
          text: '当社は、会員による本サービスの利用に際して、次の各号に定める行為を禁止します。',
          children: [
            '当社又は第三者の著作権、商標権、意匠権、特許権、実用新案権その他知的財産権を侵害する行為',
            '当社又は第三者の財産権、肖像権、パブリシティ権、人格権、名誉権、プライバシー権等を侵害する行為',
            '公序良俗又は法令に反する行為',
            '犯罪的行為、犯罪的行為に結び付く行為及び犯罪的行為を助長する行為',
            '児童ポルノ、ポルノ、わいせつ物の頒布等を規制する法令に違反する行為',
            '当社のサーバーに過度の負担を及ぼす行為',
            '本サービスの運営を妨害する行為',
            '本サービスを、本サービス本来のサービス提供目的とは異なる目的で利用する行為',
            '当社（キャスト、従業員を含む）又は第三者に不利益を与える行為',
            '当社（キャスト、従業員を含む）又は第三者に対する誹謗中傷、脅迫、嫌がらせを行う行為',
            '第三者を差別又は差別を助長する行為',
            '第三者の承諾なく個人情報又はプライバシー情報を収集し、公開する行為',
            '選挙の事前運動、選挙運動又はこれらに類似する行為及び公職選挙法に抵触する行為',
            '会員又は第三者の営業に関する宣伝をする目的で本サービスを利用する行為',
            'ねずみ講、マルチ商法を勧誘する目的で本サービスを利用する行為',
            '援助交際・売春・買春等の勧誘行為、その他性行為、わいせつな行為、面識のない異性との出会い等を目的とする行為',
            '当社のキャストに対して性行為又はサービス内容にない性交類似行為を強要する行為',
            '当社のキャストと個人的な連絡先を交換し又は本サービスを利用せず会うことを勧誘する行為',
            '録画録音機器を用いた盗撮・盗聴等の行為',
            '当社のキャストに対する他店等への引き抜き行為',
            '当社の業務に支障をきたす行為',
            '虚偽の情報を本サービスに登録する行為',
            '本規約又は個別規約で禁止する行為',
            'その他当社が不適切と判断する行為',
          ],
        },
        {
          text: '前項の禁止行為に該当するか否かの判断は、当社の裁量に行うものとし、当社は当社の判断について説明責任を負わないものとします。',
        },
        {
          text: '当社は、第１項の禁止行為に該当する行為があったと疑われる場合、商業上合理的な範囲において調査を行うものとします。この場合において、会員は、当社が調査において必要であると判断する範囲において、当社に協力する義務を負うものとします。',
        },
        {
          text: '当社は、会員の行為が、第１項の禁止行為に該当すると判断した場合、事前に通知することなく、次の各号に定めるいずれか又は全ての措置を講じることができるものとします。',
          children: [
            '本サービスの利用制限',
            '会員登録の解除による退会処分',
            'その他当社が必要と判断する行為',
          ],
        },
        {
          text: '前項の措置により会員に生じた損害について当社は一切の責任を負わないものとします。',
        },
        {
          text: '当社は、会員が本規約に違反した場合、当該会員に対し、違約金として1,000,000円を請求することができるものとします。ただし、実際に当社に生じた損害が上記金額を超える場合には、当社は、当該会員に対して、当社に生じた一切の損害（訴訟費用及び弁護士費用を含みます。）の賠償を求めることができるものとします。',
        },
      ],
    },
    {
      text: '第13条（サービス利用制限）',
      children: [
        {
          text: '当社は、次の各号に該当する場合、会員による本サービスの利用を制限することができるものとします。',
          children: [
            '会員のユーザーID及びパスワードが第三者に利用されている疑いがある場合',
            '登録情報に虚偽の情報が含まれている疑いがある場合',
            '代金等の支払が遅延している場合又は支払の遅延が生じるおそれがある場合',
            '当社から会員に対し連絡が取れない場合',
            'その他当社が必要と判断した場合',
          ],
        },
        {
          text: '前項の措置により会員に生じた損害について、当社は一切の責任を負わないものとします。',
        },
      ],
    },
    {
      text: '第14条（解除）',
      children: [
        {
          text: '当社は、会員が次の各号のいずれかに該当した場合、何らの通知等を行わずに、会員登録を解除し、退会させることができるものとします。',
          children: [
            '登録情報に虚偽の情報が含まれている場合',
            '代金等の支払が遅延している場合',
            '会員の信用状態が悪化し、代金等の支払の継続が困難である場合',
            '過去に当社の退会処分を受けていた場合',
            '会員の相続人等から会員が死亡した旨の連絡があった場合又は当社が会員の死亡の事実を確認できた場合',
            '反社会的勢力等に該当すると当社が判断した場合',
            '破産手続開始、民事再生手続開始の申立てがあった場合',
            '当社からの要請に対し誠実に対応しない場合',
            'その他当社が不適当と判断した場合',
          ],
        },
        {
          text: '前項の措置により会員に生じた損害については、当社は一切の責任を負わないものとします。',
        },
        {
          text: '第１項の措置により退会した会員は、退会時に期限の利益を喪失し、直ちに、当社に対し負担する全ての債務を履行するものとします。',
        },
        {
          text: '第７条第２項及び第３項の規定は、本条による退会の場合について準用します。',
        },
      ],
    },
    {
      text: '第15条（サービス利用環境）',
      children: [
        {
          text: '会員は、本サービスを利用するための利用端末、電子機器、通信機器、ソフトウェア、インターネット接続環境を自己の責任と費用で準備するものとします。',
        },
        {
          text: '本サービスを利用するにあたって発生する通信費等は、会員が負担するものとします。',
        },
        {
          text: 'プロキシサーバー、VPNサーバー等を経由して本サービスを利用する場合、正常に本サービスを利用できない場合があります。',
        },
      ],
    },
    {
      text: '第16条（サービス提供の中断）',
      children: [
        {
          text: '当社は、次の各号に該当する場合、本サービスの提供を中断することができるものとします。',
          children: [
            '本サービスの保守・点検を行う場合',
            '火災、停電等の事故が発生した場合',
            '天変地異等の非常事態が発生した場合',
            'システム障害',
            '業務上やむを得ない事由が生じた場合',
            'その他当社が必要と判断した場合',
          ],
        },
        {
          text: '前項の措置により会員が本サービスを利用できないことにより生じた損害等について当社は責任を負わないものとします。',
        },
      ],
    },
    {
      text: '第17条（サービスの変更、追加、廃止）',
      children: [
        {
          text: '当社は、いつでも、本サービスの全部又は一部を変更（本サービスの内容、本サービスの仕様の変更を含みますが、これらに限りません。）、追加又は廃止することができるものとします。',
        },
        {
          text: '当社は、前項による本サービスの全部又は一部の変更、追加又は廃止について、何ら責任を負うものではありません。',
        },
        {
          text: '当社は、第１項の規定により本サービスの全部又は重要な一部を変更、追加又は廃止するときは、会員に対し、相当な期間前までにその旨を通知します。ただし、緊急又はやむを得ない場合はこの限りではありません。',
        },
      ],
    },
    {
      text: '第18条（免責）',
      children: [
        {
          text: '当社は、本サービスが、会員の特定目的に適合すること及び会員が期待する品質、価値を有することを何ら保証するものではありません。',
        },
        {
          text: '当社は、本サービスの完全性、正確性、信頼性、有用性等について何ら保証するものではありません。また、当社は、本サービスに事実上又は法律上の瑕疵がないことを保証するものではありません。',
        },
        {
          text: '当社は、会員による本サービスの利用により生じた不利益、損害について、一切の責任を負わないものとします。',
        },
        {
          text: '当社は、会員が本サービスを利用できないことにより生じた不利益、損害について、一切の責任を負わないものとします。',
        },
        {
          text: '本サービスの利用に関し当社が損害賠償責任を負う場合は、当社は、会員が当社に本サービス利用の対価として直近１ヶ月間に支払った金額を上限として損害賠償責任を負うものとします。ただし、当社に故意又は重大な過失がある場合はこの限りではありません。',
        },
        {
          text: '当社が管理するサイトに設置されたリンク先のうち、第三者が管理するサイトは当社が管理するものではないため、当社は当該サイトについて一切の責任を負わないものとします。',
        },
        {
          text: '本サービスに関連して、会員間または会員と第三者間で発生したトラブル・紛争に関して、当社は一切の責任を負わないものとします。',
        },
      ],
    },
    {
      text: '第19条（知的財産権）',
      children: [
        {
          text: '本サービスで提供される映像、音声、音声、プログラム、文字、画像、イラスト、デザイン、商標、ロゴマーク、その他一切の情報（以下「本コンテンツ」といいます。）の著作権、著作隣接権、商標権、意匠権等の知的財産権は、当社又は当社に利用許諾した第三者に帰属します。',
        },
        {
          text: '会員は、本コンテンツについて、当社又は第三者の権利を侵害する一切の行為をしてはならないものとします。',
        },
        {
          text: '前項の規定に違反し、当社又は第三者の権利を侵害した者は、当社又は第三者に対し、その損害につき賠償をする責任を負うものとします。',
        },
      ],
    },
    {
      text: '第20条（事業譲渡）',
      content:
        '当社は、本サービスに関する事業を第三者に譲渡した場合には、当該事業譲渡に伴い、本サービスの運営者たる地位、本規約に基づく権利及び義務並びに会員の登録情報その他情報を当該事業譲渡の譲受人に譲渡することができるものとし、会員は、会員たる地位、本規約に基づく権利及び義務並びに会員の登録情報その他情報の譲渡につきあらかじめ同意するものとします。',
    },
    {
      text: '第21条（業務委託）',
      children: [
        {
          text: '当社は、本サービスの業務の全部または一部を、第三者（外国にある第三者を含みます。）に委託させて行わせることができるものとし、会員はこれにあらかじめ同意するものとします。',
        },
        {
          text: '前項の場合において、当社が必要と判断した場合、当社は、個人情報を第三者（外国にある第三者を含みます。）に提供することができるものとし、会員はこれにあらかじめ同意するものとします。',
        },
      ],
    },
    {
      text: '第22条（債権譲渡）',
      children: [
        {
          text: '会員は、当社が会員に対し有する債権を第三者（以下「債権譲受人」といいます。）に譲渡することがあることに、あらかじめ異議なく承諾するものとします。',
        },
        {
          text: '前項の場合において、当該債権譲渡の請求及び回収に用いるため、会員は、当社が債権譲受人に対し、会員の氏名、住所、電話番号及び債権の請求を行うために必要な情報を提供することに、あらかじめ異議なく承諾するものとします。',
        },
        {
          text: '第１項の場合において、当社及び債権譲受人は、会員への個別の通知又は譲渡承諾の請求を省略することができるものとします。',
        },
      ],
    },
    {
      text: '第23条（損害賠償責任）',
      content:
        '会員は、本サービスの利用に関連して当社に損害を与えた場合、当該損害を賠償する責任を負うものとします。',
    },
    {
      text: '第24条（分離可能性）',
      content:
        '本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により、無効又は執行不能と判断された場合であっても、本規約の残りの規定は、完全に有効なものとして、引き続き効力を有するものとします。',
    },
    {
      text: '第25条（準拠法及び専属的合意管轄裁判所）',
      children: [
        {
          text: '本規約は、日本法に準拠して解釈されるものとします。',
        },
        {
          text: '本サービス又は本規約に関し、当社と会員の間で生じた紛争の解決については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。',
        },
      ],
    },
  ],
  HOW_TO_USE_TEXT:
    '🟨1セット60分となります\n\n🟨【団体派遣】はテコガール2名から派遣可能で、お客様は1名様〜複数名様で割り勘していただく形でもご利用可能です\n\n🟨お客様1名の場合のみ、テコガール1名との〝個teco〟も可能です\n\n🟨〝個teco〟でテコガールが現地に着いた時にお客様が複数人いた場合はゲームの利用禁止または即派遣中止となります。その場合は1セット目の料金はいただきますのでお気をつけください\n\n🟨交通費は含まれております。テコガールに追加で渡さないでください\n\n🟨王様のアプリまたは王様ゲームを行った場合それ以降どのゲーム、何のゲームをしても追加料金はかかりません\n\n🟨王様のアプリから王様ゲームに移行する場合は払いなおしせず差額2万円のみのお支払いで変更スライドできます\n\n🟨生理中のテコガールは名前の横に🩸のマークがつきます\n\n🟨プロフィールの★はお客様アンケートから当店独自の計算式で算出したテコガールの評価点となります。ぜひご参考にしてください\n\n🟨ある程度どこの飲み会場所でも派遣します。ご利用のお客様以外の方がいるところでのゲームは公共のモラルに反するのでご遠慮ください\n\n🟨お客様が〝紳士であること〟を前提に派遣させていただきます。テコガールは放っておいても最高のパフォーマンスをいたしますのでどうか嫌がることはご遠慮ください。至らない点があれば最後のアンケートにて運営にご連絡ください\n\n🟨お電話などによる応答・対応は一切いたしておりませんのでご了承ください\n\n🟨別途消費税10%がかかりますが現金の場合は消費税相当分を割引させていただきます。',
};

export default TEXT_APP_INFO;
