import { createContext } from 'react';
import { EGiftPaymentStep, TGiftMessage } from 'types';

type TChatContext = {
  giftView?: TGiftMessage;
  setGiftView: (gift: TGiftMessage) => void;
  historyPaymentId?: number;
  setHistoryPaymentId: (id?: number) => void;
  stepPaymentGift?: EGiftPaymentStep;
  setStepPaymentGift: (step?: EGiftPaymentStep) => void;
};

const ChatContext = createContext<TChatContext>({
  setGiftView: () => {},
  setHistoryPaymentId: () => {},
  setStepPaymentGift: () => {},
});

export default ChatContext;
