import React, { useContext } from 'react';
import { EGiftPaymentStep, TSendMessage } from 'types';
import { GiftItem, GiftPaymentStep, ModalGift } from 'components/Gift';
import { TGift } from 'types/public';
import { ChatContext } from 'components/Chat/context';

type Props = {
  sendMessage: (payload: TSendMessage) => Promise<void>;
  listGift?: TGift[];
  femaleId?: number;
  showGift?: boolean;
};

const GiftBag = ({
  sendMessage,
  listGift = [],
  femaleId,
  showGift = false,
}: Props) => {
  // Hooks
  const { stepPaymentGift, setStepPaymentGift, setHistoryPaymentId } =
    useContext(ChatContext);

  // States
  const [openGift, setOpenGift] = React.useState<boolean>(false);
  const [selectedGift, setSelectedGift] = React.useState<TGift>();

  return (
    <>
      {showGift && (
        <div className="w-full h-2/3 grid grid-cols-4 p-5 gap-5 overflow-y-auto">
          {listGift.map((gift) => (
            <GiftItem
              key={gift.id}
              gift={gift}
              onClick={() => {
                setSelectedGift(gift);
                setOpenGift(true);
              }}
              isLocked={gift.isLocked}
            />
          ))}
        </div>
      )}
      <GiftPaymentStep
        onNextStep={() => setStepPaymentGift(+(stepPaymentGift || 0) + 1)}
        onPreviousStep={() => setStepPaymentGift(+(stepPaymentGift || 0) - 1)}
        giftId={selectedGift?.id || 0}
        onClose={() => setStepPaymentGift(undefined)}
        step={stepPaymentGift}
        femaleId={femaleId}
        onSendMessage={(gift) => {
          sendMessage({ gift, message: '' });
        }}
      />
      <ModalGift
        open={openGift}
        onClose={() => setOpenGift(false)}
        onConfirm={() => {
          setStepPaymentGift(EGiftPaymentStep.CHOOSE_CARD);
          setOpenGift(false);
          setHistoryPaymentId(undefined);
        }}
        gift={selectedGift}
        isLocked={selectedGift?.isLocked}
      />
    </>
  );
};

export default GiftBag;
